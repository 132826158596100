import React from "react";
import JummnotronSlider from "./JumbotronSlider";
import { Button } from "reactstrap";
import "./Jumbotron.css";
import ModalAddVideo from "./ModalAddVideo";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import AdComponent from "./AdComponent";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const Jumbotron = ({ movies, watchOn, changeWatchOn }) => {
  let userName = null;
  if (typeof window !== "undefined") {
    userName = localStorage.getItem("userName");
  }

  const { t } = useTranslation();

  const fr = [
    {
      slug: "/t/charade",
      poster_thumb: {
        url: "https://img.cinetimes.org/img/cache/fe/c4/fec4290be3bcd478eb66d5da8c8ea4ce.jpg",
      },
    },
    {
      slug: "/t/superman",
      poster_thumb: {
        url: "https://img.cinetimes.org/img/cache/09/b5/09b53e7c649c512dc8f044a9ce46b187.jpg",
      },
    },
    {
      slug: "/t/popeye-volume-1",
      poster_thumb: {
        url: "https://img.cinetimes.org/img/cache/04/09/04098edc68c0ba5650ddc5d9c8f87d68.jpg",
      },
    },
  ];
  const en = [
    {
      slug: "/en/t/charade-05-12-1963",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w500//69b2DSbFmtZMfHMSLp4pCC0D8V2.jpg",
      },
    },
    {
      slug: "/en/t/max-fleischers-superman-1941-1942",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200/fqwlHx8FXeUHQGqC8K1cp5hY4F.jpg",
      },
    },
    {
      slug: "/en/t/12-angry-men",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200/7sf9CgJz30aXDvrg7DYYUQ2U91T.jpg",
      },
    },
    {
      slug: "/en/t/big-buck-bunny_o8oa",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200/i9jJzvoXET4D9pOkoEwncSdNNER.jpg",
      },
    },
  ];
  const es = [
    {
      slug: "/es/t/las-originales-aventuras-animadas-de-superman",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//fqwlHx8FXeUHQGqC8K1cp5hY4F.jpg",
      },
    },
    {
      slug: "/es/t/charada",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//yOKbNqwMoGxYXGEF1IOvawQhDWH.jpg",
      },
    },
    {
      slug: "/es/t/par-impar",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//hfGqUcWh9SDtnIFz4N5kjtUWuVm.jpg",
      },
    },
  ];
  const lat = [
    {
      slug: "/es/t/las-originales-aventuras-animadas-de-superman",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//fqwlHx8FXeUHQGqC8K1cp5hY4F.jpg",
      },
    },
    {
      slug: "/es/t/charada",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//yOKbNqwMoGxYXGEF1IOvawQhDWH.jpg",
      },
    },
    {
      slug: "/es/t/par-impar",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//hfGqUcWh9SDtnIFz4N5kjtUWuVm.jpg",
      },
    },
  ];

  const manuallySetMovies = {
    es: es,
    fr: fr,
    en: en,
    lat: lat,
  };

  // Solve all button without storing
  let watchOnAll = true;
  for (const key in watchOn) {
    if (watchOn[key] === true) {
      watchOnAll = false;
    }
  }

  return (
    <div className="jumbotron bg-blue mb-0 rounded-0">
      {" "}
      {/* d-md-flex */}
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12 d-none d-md-block">
            <JummnotronSlider movies={manuallySetMovies[t("iso")]} />
          </div>
          <div className="col-md-6 col-sm-12 offset-md-1 text-white">
            <h1 className="display-5 helsinki">
              <strong>Cinetimes</strong>
            </h1>
            <hr className="my-4 bg-white" />
            {/* <p className="lead">Bienvenue sur Cinetimes. Voir gratuitement des films, séries, documentaires et dessins animés du domaine public et autres oeuvres disponibles gratuitement et légalement sur internet.</p> */}
            <p className="lead">
              <Trans>site_description</Trans>
            </p>
            {/* { !userName && */}
            <div className="d-flex flex-column flex-lg-row">
              {/* <span className="mr-1 my-auto">Montrer: </span> */}
              <div>
                {/* { userName &&
                      <ModalAddVideo auth="login">
                        <button type="button" className={`btn btn-sm btn-outline-light mr-1 mb-1`}>Ajouter un film</button>
                      </ModalAddVideo>
                    }  */}
                {/* <button type="button" className={`btn btn-sm btn-outline-light mr-1 ${watchOnAll && 'active'}`} onClick={() => changeWatchOn('all') }>Tout</button> */}
                {/* <button type="button" className={`btn btn-sm btn-outline-light mr-1 mb-1 ${ watchOn['cinetimes'] && 'active' } `} onClick={() => navigate('/browse/free')}>Films gratuits</button>
                    <button type="button" className={`btn btn-sm btn-outline-light mr-1 mb-1 ${ watchOn['cinetimes'] && 'active' } `} onClick={() => navigate('/browse/free_animation')}>Dessins animés gratuits</button> */}
                {/* <button type="button" className={`btn btn-sm btn-outline-light mr-1 mb-1 ${ watchOn['cinetimes'] && 'active' } `} onClick={() => navigate('/browse/free')}>Documentaires gratuits</button>
                    <button type="button" className={`btn btn-sm btn-outline-light mr-1 mb-1 ${ watchOn['cinetimes'] && 'active' } `} onClick={() => navigate('/browse/free')}>Sur vos services</button> */}
                {/* <button type="button" className={`btn btn-sm btn-outline-light mr-1 ${ watchOn['netflix'] && 'active' } `} onClick={() => navigate("/user/login")}>Mes services</button> */}
                {/* <button type="button" className={`btn btn-sm btn-outline-light mr-1 ${ watchOn['netflix'] && 'active' } `} onClick={() => changeWatchOn('netflix')}>Mes services</button> */}
                {/* <button type="button" className={`btn btn-sm btn-outline-light mr-1 ${ watchOn['netflix'] && 'active' } `} onClick={() => changeWatchOn('netflix')}>Gratuit</button> */}
                {/* <button type="button" className={`btn btn-sm btn-outline-light mr-1 ${ watchOn['netflix'] && 'active' } `} onClick={() => changeWatchOn('netflix')}>Netflix</button>
                  <button type="button" className={`btn btn-sm btn-outline-light mr-1 ${ watchOn['primevideo'] && 'active' } `} onClick={() => changeWatchOn('primevideo')}>Prime Video</button>
                  <button type="button" className={`btn btn-sm btn-outline-light mr-1 ${ watchOn['disneyplus'] && 'active' } `} onClick={() => changeWatchOn('disneyplus')}>Disney+</button> */}
                {/* <button type="button" className="btn btn-sm btn-outline-light mr-1 mb-1" onClick={() => changeWatchOn('all')}>Gratuit</button> */}
              </div>
            </div>
            {/* // }   */}
            {/* <button type="button" className="btn btn-sm btn-outline-light mr-1 mb-1" onClick={() => navigate(`/films/meilleurs-films-de-tous-les-temps-streaming`)}>Films Gratuits</button>
          <button type="button" className="btn btn-sm btn-outline-light mr-1 mb-1" onClick={() => navigate(`/films/meilleurs-films-animation-de-tous-les-temps-streaming`)}>Dessins Animés Gratuits</button>
          <button type="button" className="btn btn-sm btn-outline-light mr-1 mb-1" onClick={() => navigate('/films/disney-plus')}>Catalogue Disney+&nbsp;</button>
          <button type="button" className="btn btn-sm btn-outline-light mr-1 mb-1" onClick={() => navigate('/films/netflix')}>Catalogue Netflix</button>
          <button type="button" className="btn btn-sm btn-outline-light mr-1 mb-1" onClick={() => navigate('/films/amazon-prime-video/')}>Catalogue Amazon Prime Video</button>
          <button type="button" className="btn btn-sm btn-outline-light mr-1 mb-1" onClick={() => navigate(`/t/asterix-et-obelix-mission-cleopatre`)}>Astérix et Obélix : Mission Cléopâtre</button> */}
            {/* {
            userName &&
          <p className="lead">Bonjour {userName} </p>
          } */}
            {t("iso") === "fr" && (
              <>
                {/* <OutboundLink
                  href="https://twitter.com/intent/follow?screen_name=CinetimesFR"
                  target="_blank"
                >
                  <button className="btn btn-outline-light mr-2 mb-1">
                    Suivez-nous sur Twitter
                    <svg
                      className={`hover:text-blue-300 cursor-pointer mr-2 text-primary ml-1`}
                      css={css`
                        color: #1da1f2;
                        &:hover {
                          color: #1da1f2;
                          opacity: 80%;
                        }
                      `}
                      height="20"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <title>Twitter icon</title>
                      <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                    </svg>
                  </button>
                </OutboundLink>
                <OutboundLink
                  href="https://www.facebook.com/cinetimesfr"
                  target="_blank"
                >
                  <button className="btn btn-light mb-1">
                    Suivez-nous sur Facebook
                    <svg
                      className={`cursor-pointer mr-2 ml-1`}
                      height="20"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      css={css`
                        color: #1877f2;
                        &:hover {
                          opacity: 80%;
                        }
                      `}
                    >
                      <title>Facebook icon</title>
                      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                    </svg>
                  </button>
                </OutboundLink> */}
                <div className="mt-2">
                  {userName && (
                    <ModalAddVideo auth="login">
                      <button
                        type="button"
                        className={`btn btn-sm btn-outline-light mr-1 mb-1`}
                      >
                        Ajouter un film
                      </button>
                    </ModalAddVideo>
                  )}
                </div>
              </>
            )}
            <div className="mt-2">
              {t("iso") === "en" && (
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://forms.formium.io/f/62f376480a3ef9000152afda"
                >
                  <button className="btn btn-success mb-1">
                    Submit a movie
                  </button>
                </a>
              )}
            </div>
            {t("iso") === "frzb" && (
              <>
                <p>Explorer :</p>
                <Link
                  to="/search?query=France"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Films Made in France
                </Link>
                <Link
                  to="/search?query=Jean Gabin"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Jean Gabin
                </Link>
                <Link
                  to="/search?query=Erotique"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Erotique
                </Link>
                <Link
                  to="/search?query=Louis de Funes"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Louis de Funes
                </Link>
                <Link
                  to="/search?query=Western"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Western
                </Link>
                <Link
                  to="/search?query=Bourvil"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Bourvil
                </Link>
                <Link
                  to="/search?query=Belmondo"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Belmondo
                </Link>
                <Link
                  to="/search?query=Horreur"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Horreur
                </Link>
                <Link
                  to="/search?query=Animation"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Dessins Animés
                </Link>
                <Link
                  to="/search?query=Tintin"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Tintin
                </Link>
                <Link
                  to="/search?query=Sherlock Holmes"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Sherlock Holmes
                </Link>
                <Link
                  to="/search?query=Sexy"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Sexy
                </Link>
                <Link
                  to="/search?query=Hitchcock"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Hitchcock
                </Link>
                <Link
                  to="/search?query=Guerre"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Guerre
                </Link>
                <Link
                  to="/search?query=Nudité"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Nudité
                </Link>
                <Link
                  to="/search?query=Science Fiction"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Science Fiction
                </Link>
                <Link
                  to="/search?query=Chaplin"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Charlie Chaplin
                </Link>
                <Link
                  to="/search?query=John Wayne"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  John Wayne
                </Link>
                {/* <Link to="/search?query=Nudité Féminine" className="btn btn-sm btn-outline-light mr-1 mb-1">Nudité Féminine</Link> */}
                <Link
                  to="/search?query=Documentaire"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Documentaires
                </Link>
                <Link
                  to="/search?query=Mystère"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Mystère
                </Link>
                <Link
                  to="/search?query=Thriller"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Thriller
                </Link>
                <Link
                  to="/search?query=Crime"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Crime
                </Link>
                <Link
                  to="/search?query=Histoire"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Historique
                </Link>
                <Link
                  to="/search?query=Comédie"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Comédies
                </Link>
                <Link
                  to="/search?query=Muet"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Muet
                </Link>
                <Link
                  to="/search?query=Noir et Blanc"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Noir et Blanc
                </Link>
                <Link
                  to="/search?query=Drame"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Drame
                </Link>
                <Link
                  to="/collections/vieux-films-gratuits/"
                  className="btn btn-sm btn-outline-light mr-1 mb-1"
                >
                  Vieux films
                </Link>
              </>
            )}
            <p></p>
            {(userName && <span></span>) || (
              // <Link to={`/user/${userName}`}>
              //   <Button onClick color="danger" className="font-weight-bold">Mon compte</Button>
              // </Link>
              <div className="d-flex mt-4">
                {/* <ModalAuth auth="login">
                <Button outline color="light" className="font-weight-bold mr-2">Se connecter</Button>
              </ModalAuth>
              <ModalAuth auth="signup">
                <Button color="light" className="font-weight-bold">Créer un compte</Button>
              </ModalAuth> */}
              </div>
            )}
            <div id="q">
              {/* <input className="typeahead form-control" type="text" placeholder="Alfred Hitchcock, Superman..."/> */}
            </div>
            <div>
              <a className="fab fa-twitter fa-2x text-light mt-4" href=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jumbotron;
